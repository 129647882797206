* {
  font-size: 0.9rem !important;
}
.mx-0 {
  margin: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.text-center {
  text-align: center;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-2 {
  margin-left: 10px;
}
p {
  font-size: 14px;
}
.mx-auto {
  margin: auto;
}
form#notifications {
  overflow-x: scroll;
}
.checkbox-date .sc-jVOTke.cWpWjI {
  justify-content: center;
}
.flex-document {
  height: 100%;
}
html[data-theme='light'] .ant-menu-item-selected span[role='img'],
html[data-theme='light'] .ant-menu-item:hover span[role='img'],
html[data-theme='light'] .ant-menu-submenu-title:hover span[role='img'],
html[data-theme='light'] .ant-menu-submenu-title:hover i,
html[data-theme='light']
  .ant-menu-submenu-selected
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
html[data-theme='light']
  .ant-menu-submenu-selected
  .ant-menu-submenu-title
  span,
html[data-theme='light'] .ant-menu-submenu-title:hover span,
html[data-theme='light'] .ant-menu-item-selected .ant-menu-item-icon,
html[data-theme='light'] .ant-menu-item:hover .ant-menu-item-icon,
html[data-theme='light'] .ant-menu-submenu-title:hover .ant-menu-item-icon,
html[data-theme='light'] .ant-menu-item-selected a,
html[data-theme='light'] .ant-menu-item:hover a,
html[data-theme='light'] .ant-menu-submenu-title:hover a {
  color: #fff !important;
  fill: #21423f !important;
  font-weight: bold;
  /* text-decoration: underline; */
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.w-100 {
  width: 100%;
}
.modalPaiments .ant-row.ant-form-item-row {
  flex-direction: column;
}
.bg-img img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  border-radius: 10px 10px 0 0;
  height: 100%;
  object-fit: cover;
}
.btn-cta {
  background-color: #76d5cb;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
}
.bg-img {
  height: 100px;
  margin: -25px -25px 25px;
  position: relative;
}
.float-type-abonnement {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2000;
  background-color: var(--primary-color);
  color: var(--alert-text-color);
  font-size: 14px;
  padding: 10px 30px;
  border-radius: 50px 0 0;
  box-shadow: -3px -3px 6px #fff;
}
.ant-avatar-image.loading::after {
  background-color: #000000bf;
  content: 'Chargement...';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ant-primary-color-active);
}
.ant-popover.ant-popconfirm.ant-popover-placement-top {
  max-width: 320px;
}
.upload-pdp {
  display: none;
}
.modalPaiments .ant-col.ant-form-item-label {
  justify-content: flex-start;
  display: flex;
}
.item-abonnement,
.item-abonnement .ant-card-head {
  background-color: var(--primary-color);
  color: var(--background-color) !important;
  text-align: center;
}
.h-100vh-abonnement {
  height: calc(100vh - 150px);
}
.h-100vh {
  height: 100vh;
}
.justify-start {
  justify-content: flex-start;
}
img.img-user {
  width: 30px;
  height: 30px;
  border: 1px #76d5cb solid;
  border-radius: 5px 0 0 5px;
}
.pl-3 {
  padding-left: 15px;
}
.file-dossier-patient
  button.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
  display: none;
}
.file-doc-prof-dash .ant-upload-list.ant-upload-list-picture-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.file-doc-prof-dash
  button.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
  display: none;
}
.large-icon svg {
  font-size: 25px;
}
.max-1000 .ant-modal-content {
  max-width: 1000px;
  margin: auto;
}
.card-user .ant-card-body {
  padding: 0 !important;
  padding-right: 5px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.card-user .ant-card-body a {
  padding-left: 5px;
  font-size: 12px;
}
.d-block {
  display: block;
}
.m-auto {
  margin: auto;
}
.petite-buts .ant-upload.ant-upload-select-picture-card {
  width: 32px;
  height: 32px;
  margin: auto;
  margin-left: 10px;
}
.ant-alert-error {
  background-color: transparent;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.mb-3 {
  margin-bottom: 15px;
}
.ant-card-default {
  box-shadow: 2px 3px 6px #0000002b;
  background-color: #0000000d;
}
.px-5 {
  padding-left: 25px;
  padding-right: 25px;
}
.px-10 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
.mb-5 {
  padding-bottom: 2.25rem;
}
.logo-global {
  width: 75%;
  height: fit-content;
  object-fit: contain;
}
main#main-content {
  padding-top: 0;
}
.tabs-item-custio .ant-tabs-tab {
  margin-left: 0 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  /* background-color: var(--ant-primary-color-hover); */
  border-radius: 13px;
  color: var(--ant-primary-color);
}
.tabs-item-custio .ant-tabs-nav::before {
  display: none !important;
}
.tabs-item-custio .ant-tabs-tab .click-button {
  padding: 12px;
  border: 1px var(--ant-primary-color-hover) solid !important;
  border-radius: 13px !important;
}
.text-primary {
  color: var(--primary-color);
}
.mb-1 {
  margin-bottom: 11px;
}

button.btn.ant-btn-primary.cust {
  padding: 10px;
  border-radius: 12px;
  font-size: 15px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}
.tabs-item-custio .ant-tabs-tab {
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  padding-right: 7px !important;
}

.click-button-tag {
  padding: 14px 12px;
  border-radius: 13px;
  border: 1px var(--ant-primary-color-hover) solid;
  color: var(--text-main-color);
}
.close-button-tag {
  margin-left: 10px;
  padding-top: 3px;
  color: var(--text-main-color);
}
.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.ant-tag-close-icon {
  color: var(--text-main-color);
  margin-left: 15px;
}

p {
  margin: 0;
}

.ant-picker-cell:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
  display: none;
}

.ant-picker-cell:not(.ant-picker-cell-in-view) {
  pointer-events: none;
  user-select: none;
}

.rbc-event {
  font-size: 12px;
}

.rbc-event.rbc-selected {
  background-color: var(--primary-color) !important;
}

.rbc-current-time-indicator {
  height: 3px !important;
  background-color: var(--primary-color) !important;
}

.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-image-preview-img-wrapper::before {
  height: auto;
}
